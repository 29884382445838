import React, { Component } from "react"
import PropTypes from "prop-types"

import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import { Segment, Grid } from "semantic-ui-react"
import * as numberHelpers from "../../helpers/numberHelpers"
import { withTranslation } from "react-i18next"
import { ShimmerThumbnail } from "react-shimmer-effects"

const ShimmerEffect = () => {
  return (
    <>
      <ShimmerThumbnail height={30} width={150} rounded />
      <ShimmerThumbnail height={15} width={200} rounded />
    </>
  )
}

class Stats extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      statsData: {}
    }
  }

  async componentDidMount() {
    await this.props.dispatch({
      type: "GET_SUMMARY_DATA",
      payload: {},
      callback: this.afterLoadServices
    })
  }

  afterLoadServices = (message, response) => {
    this.setState({ statsData: response, isLoading: false })
  }

  static propTypes = {
    fleets: PropTypes.array.isRequired
  }

  render() {
    const { t } = this.props
    const { isLoading, statsData } = this.state
    return (
      <Grid columns="equal" stretched stackable>
        <Grid.Row>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item"}>
              {isLoading ? (
                <ShimmerEffect />
              ) : (
                <>
                  {statsData && statsData.total_fleets}
                  <span>{t("totalFleetsLabel")}</span>
                </>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item"}>
              {isLoading ? (
                <ShimmerEffect />
              ) : (
                <>
                  {statsData && statsData.total_vehicles}
                  <span>{t("totalVehiclesLabel")}</span>
                </>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment vertical className={"dash-stats-item"}>
              {isLoading ? (
                <ShimmerEffect />
              ) : (
                <>
                  {statsData && numberHelpers.formattedPrice(statsData.total_m_cost)}
                  <span>{t("totalSpendLabel")}</span>
                </>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
} // class Stats

export default withTranslation("fmcDashboard")(Stats)
