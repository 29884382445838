import React from "react"
import { Button, Icon } from "semantic-ui-react"
import Modal from "react-modal"
import { useTranslation } from "react-i18next"
import { VEHICLE } from "../../../constants/namespaces"
import { connect } from "react-redux"
import { OIL_CHANGE } from "../../../constants/services"

function RecommendedServiceModal({
  closeModal,
  onSave,
  serviceOptions,
  isOpenModel,
  serviceName,
  vehicle
}) {
  const { t } = useTranslation("recommendedServiceModal")
  const newServiceOptions = serviceOptions.map((item) => {
    return {
      name: item,
      checked: false
    }
  })

  const [service, setService] = React.useState(newServiceOptions)

  const [selectedServices, setSelectedServices] = React.useState([])

  const onChanges = (ev) => {
    const a = service.map((item) => {
      return {
        ...item,

        checked: item.name === ev.target.value && !item.checked ? true : false
      }
    })

    setService(a)
    const services = a.filter((element) => {
      if (element.checked) {
        return element.name
      }
    })

    setSelectedServices(services)
  }

  React.useEffect(() => {}, [service])
  return (
    <>
      <Modal
        className="customStyles"
        isOpen={isOpenModel}
        overlayClassName="Overlay"
        onRequestClose={() => closeModal(service)}
        shouldCloseOnOverlayClick={false}
      >
        <div>
          <div className="modelCheckbox">
            <h3>
              {t("recommended")} {serviceName} {t("forYourCar")}
              <Icon
                name="times"
                style={{
                  textAlign: "end",
                  position: "absolute",
                  right: "1px",
                  top: "2px",
                  cursor: "pointer"
                }}
                onClick={() => closeModal(service)}
                size="small"
              />
            </h3>
            <p className="title-meta">{t("pleaseSelect")}</p>
            {serviceOptions &&
              service.map((item, i) => {
                return (
                  <div className="modelCheckboxContainer">
                    <input
                      type="radio"
                      id={item[i]}
                      name="name"
                      checked={item.checked}
                      className="modal-custom-radio"
                      value={item.name}
                      onClick={(ev) => {
                        // ev.preventDefault()
                        onChanges(ev)
                      }}
                    />

                    <p>{item.name}</p>
                    {serviceName == OIL_CHANGE && item.name == vehicle.oil_type_name ? (
                      <div
                        style={{
                          marginLeft: "7px",
                          width: "44px",
                          height: "20px",
                          textAlign: "center",
                          borderRadius: "7px"
                        }}
                      >
                        <p className="recommended-label">{t("best")}</p>
                      </div>
                    ) : null}
                  </div>
                )
              })}
          </div>
          <Button
            disabled={!selectedServices.length ? true : false}
            fluid
            content={t("save")}
            onClick={() => onSave(selectedServices.length ? selectedServices[0].name : undefined)}
          />
        </div>
      </Modal>
    </>
  )
}
function mapStateToProps(state) {
  let vehicle = state.vehicles.vehicle || {}

  return {
    vehicle: vehicle || {}
  }
}

export default connect(mapStateToProps)(RecommendedServiceModal)
