import Frisbee from "frisbee"
import { applicationIdentifier } from "../constants/application"
const BRACKETS = "brackets"

export class Api {
  constructor() {
    // NOTE: Because the API runs on a different port than this app in development, the localhost dev URI has to be set explicitly.
    if (process.env.NODE_ENV === "production") {
      this.baseUri = window.location.origin
    } else {
      this.baseUri = process.env.REACT_APP_API_HOST
    }

    this.apiUrl = this.getApiUrl()

    this.basePath = "/api/v4"
    this.basePathV6 = "/api/v6"
    this.commonHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-AppName": applicationIdentifier
    }
    this.utility = this.setNoAuthApi()
  }

  setNoAuthApi() {
    return new Frisbee({
      baseURI: this.baseUri,
      headers: this.commonHeaders,
      arrayFormat: BRACKETS
    })
  }

  setAuthApi(authHeaders) {
    this.utility = new Frisbee({
      baseURI: this.baseUri,
      headers: {
        ...this.commonHeaders,
        ...authHeaders
      },
      arrayFormat: BRACKETS
    })
  }

  constructURLWithParams(route, params = {}) {
    let url = route
    let index = 0

    if (!route.endsWith("?")) {
      url += "?"
    }

    Object.keys(params).forEach((paramName) => {
      if (index === 0 && params[paramName]) {
        url += `${paramName}=${params[paramName]}`
        index += 1
      } else if (params[paramName]) {
        url += `&${paramName}=${params[paramName]}`
        index += 1
      }
    })

    return url
  }

  path(endPath) {
    return this.basePath + endPath
  }

  pathV6(endPath) {
    return this.basePathV6 + endPath
  }

  extractFirstSubDomain = (host) => {
    if (!host) {
      host = window.location.hostname
    }

    let firstPeriod = host.indexOf(".")
    let subdomain

    if (firstPeriod !== -1) subdomain = host.substring(0, firstPeriod).toLowerCase()
    else subdomain = host

    return subdomain
  }

  getApiUrl = () => {
    if (process.env.NODE_ENV === "development") {
      return "http://localhost:3001"
    } else {
      let host = window.location.hostname
      let subDomain = this.extractFirstSubDomain(host)
      return host
        .replace(subDomain, "api")
        .replace("fleetadvise", "caradvise")
        .replace("fleetmaintenancehub", "caradvise")
    }
  }
} // class Api

const api = new Api()

export default api
