import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "../../elements/Button"
import Select from "../../elements/Select"
import { Segment, Grid } from "semantic-ui-react"
import { formatCurrency } from "../../utilities/currency"
import MiniLoader from "../shared/MiniLoader"
import { withTranslation } from "react-i18next"
import { MD_HD_VEHICLE_TYPE_ID } from "../../constants/application"
import { connect } from "react-redux"
import { OIL_CHANGE } from "../../constants/services"

class SelectedService extends Component {
  static propTypes = {
    onRemoval: PropTypes.func.isRequired,
    onServiceOptionChange: PropTypes.func.isRequired,
    service: PropTypes.object.isRequired
  }

  positionSelectOptions() {
    const { service, services } = this.props
    const selectedServices = services.filter((item) => item.id == service.id)
    const positionOptions = service.positionOptions || []
    const positions = selectedServices.length > 0 && selectedServices[0].positions
    return (
      positions &&
      positions.map((position) => {
        return {
          label: position,
          value: position
        }
      })
    )
  }

  renderPositionsSelect() {
    const { onServiceOptionChange, service, vehicle, t } = this.props
    const selectedPosition =
      service && service.positionOptions.length > 0 && service.positionOptions[0]
    // NOTE: Placeholder is "Select Option", rather than "Select Position", because certain services, such as "Oil Change", store non-position options, such as the type of oil, in the positions field.
    if (service.hasOwnProperty("position")) {
      return (
        <Segment className={"service_request__options_select_container"}>
          <Select
            className={"service_request__options_select"}
            onChange={(event) => onServiceOptionChange(service.id, "position", event.target.value)}
            options={this.positionSelectOptions()}
            placeholder={t("selectOption")}
            value={
              service.position ||
              selectedPosition ||
              (service.name == OIL_CHANGE && vehicle && vehicle.oil_type_name)
            }
            // defaultValue={service.position}
          />
        </Segment>
      )
    } else {
      return null
    }
  }

  render() {
    const { onRemoval, service, price, servicePricesLoading, t, vehicle_type_id } = this.props

    let servicePrice
    if (vehicle_type_id === MD_HD_VEHICLE_TYPE_ID) {
      servicePrice = t("priceAtShopLbl")
    } else {
      if (price && price.price_estimates) {
        if (service.position) {
          servicePrice = (
            price.price_estimates.find((pe) => pe.position === service.position) || {}
          ).shop_price_estimate
        } else if (service.positionOptions && !service.positionOptions[0]) {
          servicePrice = (price.price_estimates[0] || {}).shop_price_estimate
        }
        servicePrice = servicePrice ? formatCurrency(servicePrice) : t("priceAtShopLbl")
      }
    }

    return (
      <Segment.Group style={{ marginBottom: "10px" }}>
        <Segment>
          <Grid columns="equal">
            <Grid.Column>
              <div>{service.name}</div>
              {servicePricesLoading ? (
                <MiniLoader inverted={false} />
              ) : (
                <div className="approvals_form_field__price_shop">{servicePrice}</div>
              )}
            </Grid.Column>

            <Grid.Column textAlign="right">
              <Button label={t("removeLabel")} onClick={() => onRemoval(service.id)} />
            </Grid.Column>
          </Grid>
        </Segment>

        {this.renderPositionsSelect()}
      </Segment.Group>
    )
  }
} // class SelectedService

function mapStateToProps(state) {
  let vehicle = state.vehicles.vehicle || {}
  return {
    vehicle: vehicle || {},
    services: state.services.services || {}
  }
}

export default connect(mapStateToProps)(withTranslation("common")(SelectedService))
