import * as Sentry from "@sentry/react"
import { FMC } from "../constants/roles"

export function addOrUpdateSentryContext(userData) {
  try {
    Sentry.setUser({
      id: userData.id,
      email: userData.email,
      username: userData.name
    })

    if (userData.roles && userData.roles.includes(FMC)) {
      Sentry.setTag(FMC, "true")
    }
  } catch (e) {
    console.error("UNABLE TO SET SENTRY CONTEXT", e)
  }
}
