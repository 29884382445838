import React from "react"
import { withTranslation } from "react-i18next"
import { Form, Input, Popup } from "semantic-ui-react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import moment from "moment"
import { ProgressHUD } from "../../shared/ProgressHUD"
import Button from "../../../elements/Button"
import CustomNotification from "../../shared/CustomNotification"
import { Date_Format } from "../../../constants/years"

class VehicleDetails extends React.Component {
  constructor(props) {
    super(props)
    const { vehicle } = props

    this.state = {
      saveButtonEnabled: false,
      loading: false,
      mileage: (vehicle && vehicle.predicted_current_miles) || "",
      lastMaintenanceMileage: (vehicle && vehicle.last_maintenance_miles) || "--",
      nextMaintenanceDate: vehicle && vehicle.next_maintenance_date,
      lastRecordedMileage:
        (vehicle && vehicle.odometer_config && vehicle.odometer_config.quantity) || "--",
      mileagePerMonth:
        (vehicle &&
          vehicle.odometer_per_month_config &&
          vehicle.odometer_per_month_config.quantity) ||
        1000,

      initialMileage: (vehicle && vehicle.predicted_current_miles) || "",
      initialLastMaintenanceMileage: (vehicle && vehicle.last_maintenance_miles) || "--",
      initialNextMaintenanceDate: (vehicle && vehicle.next_maintenance_date) || "--/--/----",
      initialLastRecordedMileage:
        (vehicle && vehicle.odometer_config && vehicle.odometer_config.quantity) || "--",
      initialMileagePerMonth:
        (vehicle &&
          vehicle.odometer_per_month_config &&
          vehicle.odometer_per_month_config.quantity) ||
        1000,
      loading: false
    }
  }

  async handleChange(event, rgxp = /\//) {
    const target = event.target
    const value = target.value.replace(rgxp, "")
    const name = target.name

    if (await this.anyNewValuesPresent(name, value))
      this.setState({
        [name]: value,
        saveButtonEnabled: true
      })
    else
      this.setState({
        [name]: value,
        saveButtonEnabled: false
      })
  }

  anyNewValuesPresent = (attrKey, attrVal) => {
    const submittableAttrMap = {
      mileage: this.state.initialMileage || "",
      lastMaintenanceMileage: this.state.initialLastMaintenanceMileage || "",
      nextMaintenanceDate: this.state.initialNextMaintenanceDate || "",
      lastRecordedMileage: this.state.initialLastRecordedMileage || "",
      mileagePerMonth: this.state.initialMileagePerMonth || 1000
    }
    return submittableAttrMap[attrKey] != attrVal
  }

  repopulateInitialVehicleAttrState = () => {
    // When there's no hard refresh, maintain control of the ability to submit
    // with freshly seeded attrs
    const { vehicle } = this.props
    this.setState({
      initialMileage: this.state.miles || "",
      initialLastMaintenanceMileage: this.state.lastMaintenanceMileage || "",
      initialNextMaintenanceDate: this.state.nextMaintenanceDate || "",
      initialLastRecordedMileage: this.state.lastRecordedMileage || "",
      initialMileagePerMonth: this.state.mileagePerMonth || 1000,
      nextMaintenanceDate: this.props.vehicle && this.props.vehicle.next_maintenance_date,
      lastRecordedMileage:
        (vehicle && vehicle.odometer_config && vehicle.odometer_config.quantity) || "--"
    })
  }

  async callUpdate() {
    const { mileage, mileagePerMonth, lastRecordedMileage, lastMaintenanceDate } = this.state
    const { vehicle, updateVehicleData, t } = this.props
    let milesConverted = parseInt(mileage, 10)
    let milesPerMonthConverted = parseInt(mileagePerMonth, 10)
    if (
      milesConverted >= 0 &&
      mileagePerMonth >= 500 &&
      (mileagePerMonth == null || milesPerMonthConverted >= 0)
    ) {
      this.setState({ loading: true })

      const response = await this.props.dispatch({
        type: "VEHICLE_CREATE_OR_UPDATE_SAGA",
        payload: {
          id: vehicle.id,
          odometer: milesConverted,
          odometer_per_month: milesPerMonthConverted,
          last_maintenance_miles: lastRecordedMileage,
          last_maintenance_date: lastMaintenanceDate
        },
        callback: this.afterUpdate
      })
    } else {
      this.setState({ saveButtonEnabled: false })
      return CustomNotification("danger", "Error", t("notValidNumberforMileage"), t)
    }
    // Reset the Save button for subsequent edits/saves without refresh
    this.setState({ saveButtonEnabled: false })
  }

  afterUpdate = (status, data) => {
    const { t } = this.props
    if (status == "success") {
      const { dispatch, vehicle } = this.props
      CustomNotification("success", "Success", t("vehicleUpdateSuccessTitle"), t)
      dispatch({
        type: "PULL_OEM_MAINTENANCE_SCHEDULES",
        payload: {
          vehicle
        },
        callback: this.afterUpdateOemData
      })
      this.repopulateInitialVehicleAttrState()
    } else {
      this.repopulateInitialVehicleAttrState()
      return CustomNotification("danger", "Error", data.error, t)
    }
  }

  afterUpdateOemData = (status) => {
    if (status == "success") {
      this.setState({ loading: false })
    }
  }

  render() {
    const {
      mileage,
      lastMaintenanceMileage,
      nextMaintenanceDate,
      lastRecordedMileage,
      mileagePerMonth,
      saveButtonEnabled,
      loading
    } = this.state
    const { t, vehicle } = this.props
    const lastServiceAt =
      lastMaintenanceMileage && vehicle.last_maintenance_date
        ? t("atLbl") +
          lastMaintenanceMileage +
          t("miLabel") +
          " - " +
          moment(vehicle.last_maintenance_date).format("MM/DD/YYYY")
        : "--"
    const nextMaintenanceAt = nextMaintenanceDate
      ? moment(nextMaintenanceDate).format(Date_Format.MM_DD_YY)
      : "--/--/----"
    return (
      <div>
        {loading && <ProgressHUD />}
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>
                {t("estimatedCurrentMileageLabel")}
                <Popup
                  content={t("estimatedCurrentMileageInfoLabel")}
                  pinned
                  position="right center"
                  size="tiny"
                  trigger={
                    <FontAwesomeIcon className="info-Icon-retail-price" icon={faInfoCircle} />
                  }
                />
              </label>
              <Input
                value={mileage}
                onChange={(e) => this.handleChange(e, /\D/)}
                name="mileage"
                type="text"
                size="mini"
                icon="pencil alternate"
              />
            </Form.Field>
            <Form.Field>
              <label>
                {t("lastMaintenanceMileageLabel")}
                <Popup
                  content={t("lastMaintenanceMileageInfoLabel")}
                  pinned
                  position="right center"
                  size="tiny"
                  trigger={
                    <FontAwesomeIcon className="info-Icon-retail-price" icon={faInfoCircle} />
                  }
                />
              </label>
              <Input
                value={lastMaintenanceMileage}
                name="lastMaintenanceMileage"
                type="text"
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label style={{ minWidth: "max-content" }}>
                {t("nextMaintenanceDateLabel")}
                <Popup
                  content={t("nextMaintenanceDateInfoLabel")}
                  pinned
                  position="right center"
                  size="tiny"
                  trigger={
                    <FontAwesomeIcon className="info-Icon-retail-price" icon={faInfoCircle} />
                  }
                />
              </label>
              <Input value={nextMaintenanceAt} name="lastMaintenanceDate" type="text" readOnly />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>
                {t("lastRecordedMileageLabel")}
                <Popup
                  content={t("lastRecordedMileageInfoLabel")}
                  pinned
                  position="right center"
                  size="tiny"
                  trigger={
                    <FontAwesomeIcon className="info-Icon-retail-price" icon={faInfoCircle} />
                  }
                />
              </label>
              <Input value={lastRecordedMileage} name="lastRecordedMileage" type="text" readOnly />
            </Form.Field>
            <Form.Field>
              <label>
                {t("estimatedMilesPerMonthLabel")}
                <Popup
                  content={t("estimatedMileagePerMonthInfoLabel")}
                  pinned
                  position="right center"
                  size="tiny"
                  trigger={
                    <FontAwesomeIcon className="info-Icon-retail-price" icon={faInfoCircle} />
                  }
                />
              </label>
              <Input
                value={mileagePerMonth}
                onChange={(e) => this.handleChange(e, /\D/)}
                name="mileagePerMonth"
                type="text"
                size="mini"
                icon="pencil alternate"
              />
            </Form.Field>
            <Form.Field>
              <label style={{ minWidth: "max-content" }}>
                {t("lastCompletedServiceDateAndIntervalLabel")}
                <Popup
                  content={t("lastCompletedServiceDateAndIntervalInfoLabel")}
                  pinned
                  position="right center"
                  size="tiny"
                  trigger={
                    <FontAwesomeIcon className="info-Icon-retail-price" icon={faInfoCircle} />
                  }
                />
              </label>
              <Input
                value={lastServiceAt}
                name="lastCompletedServiceDateAndInterval"
                type="text"
                readOnly
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Button disabled={!saveButtonEnabled} label={t("Save")} onClick={() => this.callUpdate()} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let user = state.users.currentUser
  let vehicle = state.vehicles.vehicle || {}
  return {
    user,
    vehicle
  }
}
export default connect(mapStateToProps)(withTranslation("maintenanceSchedule")(VehicleDetails))
